<template lang="pug">
CanvasModal.rendering-modal(
  :is-show="true"
  :is-grey="!isMobile"
  :no-padding="true"
  :hide-close-button="true"
)
  .rendering-modal__container--carousel
    iframe(src="https://offeo.com/download-modal")

  .rendering-modal__container--progress
    template(v-if="showVideoPercentageProgress")
      .rendering-modal__progress
        .progress-text
          p.text {{ progressText }}
          p.text(v-if="!isOnQueue") {{ `${percent}%`}}

        //- .progress-bar(v-if="!isOnQueue")
        //-   template(v-if="showVideoPercentageProgress")
        //-     .download-modal__progress
        //-       Lottie.progress-bar(
        //-         ref="progressBar"
        //-         :options="animatedProgressbarOptions"
        //-         :no-overflow="true"
        //-       )

        .progress-bar
          template(v-if="showVideoPercentageProgress")
            .download-modal__progress
              Lottie.progress-bar(
                ref="progressBar"
                :options="animatedProgressbarOptions"
                :no-overflow="true"
              )

      BaseButton.btn-cancel-download(
        :is-canvas="true"
        :is-tall="true"
        :is-hollow="true"
        :is-loading="isCancelling"
        @click="cancelRendering"
      ) Cancel


    //- For Designer - Rendering templates
    template(v-else)
      h2.download-modal__title Generating scene previews

      BasePreloader(v-if="scenePreviewTextCount === ''")
      span.download-modal__percentage(v-else) {{ scenePreviewTextCount }}

      button.btn-cancel-download(
        type="button"
        :is-tall="true"
        @click="closeModal"
      ) Close
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { TimelineLite } from 'gsap/TweenMax';

import Api from '@/services/api/Api';
import { homeUrl, dashboardUrl } from '@/assets/scripts/variables';

import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';

import renderMixin from '@/components/mixins/render-mixins';
import videoRendering from '@/assets/data/video-rendering.json';
import colorfulBar from '@/assets/data/colorful-bar.json';

let counterInterval;
let pingExportProgressLoop;

export default {
  name: 'RenderingModal',
  mixins: [renderMixin],
  props: {
    isMobile: { type: Boolean, default: false },
  },
  components: {
    Lottie,
    CanvasScene,
  },
  data() {
    return {
      downloadModalSize: {
        width: 500,
        height: 400,
      },
      timelineBar: new TimelineLite(),
      isShow: false,
      isCancelling: false,
      homeUrl: homeUrl(),
      myProjectsPage: `${dashboardUrl()}`,
      animatedRenderingOptions: {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: videoRendering,
      },
      animatedProgressbarOptions: {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'none',
        },
        animationData: colorfulBar,
      },
      fakeCounter: 0,
      runPingExportProgress: true,
    };
  },
  computed: {
    ...mapState([
      'isTemplate',
    ]),
    ...mapState('userData', ['isNotifiedOfExports']),
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('canvasElements', ['getProjectId']),
    ...mapGetters('exportDetails', ['getExportDetailStates']),
    ...mapState('exportDetails', ['queuePosition', 'progress', 'isTerminated', 'isCancelled', 'isCompleted']),
    percent() {
      // let currentPercent = this.fakeCounter; // mock percent
      // if (this.progress > 10) {
      //   currentPercent = this.progress;
      // }
      // return this.progress;

      let currentPercent = this.fakeCounter; // mock percent
      if (this.getExportDetailStates.progress > 10) {
        currentPercent = this.getExportDetailStates.progress;
      }
      return currentPercent;
    },
    progressText() {
      let text = '';
      if (this.percent >= 0 && this.percent < 30) text = 'Preparing';
      if (this.percent >= 30 && this.percent < 85) text = 'Rendering';
      if (this.percent >= 85 && this.percent < 100) text = 'Compiling';
      if (this.percent === 100) text = 'Finalizing';

      return `${text}...`;
    },
    showVideoPercentageProgress() {
      // console.log('showVideoPercentageProgress', this.getExportDetailStates.type)
      return !this.isCreatingImage;
    },
    isOnQueue() {
      // return this.queuePosition >= 1 || this.percent === 0;
      return this.percent === 0;
    },
    processingMessage() {
      const { queuePosition } = this.getExportDetailStates;

      if (queuePosition > 1) {
        return `Position in Queue: ${queuePosition}`;
      }

      return 'Processing your render...';
    },
    scenePreviewTextCount() {
      return this.getExportDetailStates.scenePreviewTextCount;
    },
    isCreatingImage() {
      return (
        this.getExportDetailStates.type === 'jpg'
        || this.getExportDetailStates.type === 'png'
        || this.getExportDetailStates.type === 'inspiration_assets'
      );
    },
  },
  methods: {
    ...mapMutations('exportDetails', ['updateExportDetailState']),
    ...mapActions('canvasElements', ['saveProject']),
    closeModal() {
      this.updateExportDetailState({
        isExporting: false,
      });
    },
    calculateProgressBar(val) {
      // console.log('calculateProgressBar', val, this.$refs.progressBar);
      this.$nextTick(() => {
        const progressBarContainer = document.querySelectorAll('.progress-bar svg')[0];
        const progressBar = document.querySelectorAll('.progress-bar rect')[0];
        if (typeof this.$refs.progressBar !== 'undefined') {
          const currentProgress = val * (progressBarContainer.width.baseVal.value / 100);
          progressBar.setAttribute('width', `${currentProgress}`);
        }
      });
    },
    updateFakeCounter() {
      if (this.fakeCounter < 30) {
        const timeout = Math.floor((Math.random() * (5 - 1) + 10) * 1000);
        // console.log('fakeCounter', this.fakeCounter, timeout);
        setTimeout(() => {
          this.fakeCounter += 1;
          this.updateFakeCounter();
        }, timeout);
      }
    },
    pingExportProgress() {
      if (!this.runPingExportProgress || this.isCreatingImage) {
        clearTimeout(pingExportProgressLoop);
        return;
      }

      // ping only for video exports
      Api.pingExportProgress({
        id: this.getProjectId,
        is_template: this.isTemplate,
      })
        .then((response) => {
          const { results } = response?.data;
          if (results === null || !!results?.cancelled || !!results?.failed) {
            this.$emit('download-failed');
            this.updateExportDetailState({
              isExporting: false,
            });
            this.runPingExportProgress = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          pingExportProgressLoop = setTimeout(() => {
            this.pingExportProgress();
          }, 5000);
        });
    },
  },
  created() {
    this.saveProject();
  },
  mounted() {
    this.fakeCounter = 0;
    this.calculateProgressBar(this.percent);
    this.isShow = this.getExportDetailStates.isExporting;

    const { isExportStarted } = this.getExportDetailStates;

    // if (!this.isCreatingImage) {
    //   if (isExportStarted) {
    //     this.exportVideoStatus();
    //   } else {
    //     // this.exportVideo();
    //   }
    // }

    if (!this.isCreatingImage) {
      if (isExportStarted) {
        this.exportVideoStatus();
      } else {
        // this.exportVideo();
      }
    }

    // Fake counter
    this.updateFakeCounter();
    setTimeout(() => {
      this.pingExportProgress();
    }, 5000);
  },
  watch: {
    getExportDetailStates: {
      handler(val) {
        // if (val.isCompleted) {
        //   this.finishRender();
        // }

        if (val.isTerminated) {
          this.$emit('download-failed');
        }

        if (val.isCancelled) {
          this.$emit('download-cancelled');
        }

        if (val.isTerminated || val.isCancelled) {
          console.log('getExportDetailStates:isCancelled', val);
          // todo cancel progress
          // this.setIsRendering(false);
          this.updateExportDetailState({
            isExporting: false,
          });
        }
      },
      deep: true,
    },
    percent: {
      handler(val) {
        this.calculateProgressBar(val);
        if (val > 30) {
          // if it is not fake counter, remove the interval counter
          this.runPingExportProgress = false;
        }
      },
      deep: true,
      immediate: true,
    },
    showVideoPercentageProgress: {
      handler(val) {
        this.calculateProgressBar(this.percent);
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.fakeCounter = 0;
    clearInterval(counterInterval);
    clearTimeout(pingExportProgressLoop);
  },
};
</script>

<style lang="scss">
.rendering-modal {
  .canvas-modal__overlay {
    background: rgba(0, 0, 0, 0.9);
  }

  .canvas-modal__content {
    height: 680px !important;
    background: none;
    border-radius: 10px;

    .content {
      overflow: hidden;
    }
  }

  .rendering-modal__container--carousel {
    height: 75%;
    background: $darkGrey5;

    iframe {
      width: 100%;
      border: 0;
      height: 100%;
      padding: 50px 0;
    }
  }

  .rendering-modal__container--progress {
    height: 25%;
    background: $darkGrey700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
  }

  .btn-cancel-download {
    padding: 20px 65px;
    color: $light;
    font-size: 1em;
    font-weight: 600;
    margin-top: 10px;

    &:hover {
      color: $black;
    }
  }
}

.rendering-modal__progress {
  width: 60%;
  display: flex;
  flex-direction: column;

  .progress-text {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    color: $light;

    .text {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.download-modal__progress {
  position: relative;

  .mobile-project & {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
  }

  .progress-bar {
    position: absolute;
    width: 100%;
    height: 5px !important;
    bottom: 0;

    svg {
      background: $darkGrey5;
      border-radius: 100px;

      // To make the svg border-radius only for the colorful progressbar
      rect {
        rx: 15 !important;
        ry: 160 !important;
      }
    }

    .mobile-project & {
      width: calc(100% - 70px);
      height: 2px;
      bottom: 8px;
    }
  }
}
</style>
